import { ref, watch, computed } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useSuppliersList() {
  // Use toast
  const toast = useToast()

  const refSupplierListTable = ref(null)

  const currentQuery = router.currentRoute.query

  // Table Handlers
  const tableColumns = [
    { key: 'image', sortable: false },
    { key: 'name', sortable: true },
    { key: 'type', sortable: false },
    { key: 'status', sortable: false },
    { key: 'id', sortable: true, label: 'Created at' },
    { key: 'actions' },
  ]
  const perPage = ref(+currentQuery.perPage || 10)
  const totalSuppliers = ref(100)
  const currentPage = ref(+currentQuery.currentPage || 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(currentQuery.searchQuery || '')
  const sortBy = ref(currentQuery.sortBy || 'name')
  const isSortDirDesc = ref(currentQuery.isSortDirDesc === 'true' || true)
  const typeFilter = ref(currentQuery.typeFilter || null)
  const statusFilter = ref(currentQuery.statusFilter || null)

  const dataMeta = computed(() => {
    const localItemsCount = refSupplierListTable.value ? refSupplierListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSuppliers.value,
    }
  })

  const refetchData = () => {
    const query = {}
    if (currentPage.value) {
      query.currentPage = currentPage.value
    }
    if (perPage.value) {
      query.perPage = perPage.value
    }
    if (searchQuery.value) {
      query.searchQuery = searchQuery.value
    }
    if (typeFilter.value) {
      query.typeFilter = typeFilter.value
    }
    if (statusFilter.value) {
      query.statusFilter = statusFilter.value
    }
    if (sortBy.value) {
      query.sortBy = sortBy.value
    }
    query.isSortDirDesc = isSortDirDesc.value ? 'true' : 'false'
    router.push({ name: router.currentRouteName, query })
    refSupplierListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  watch([searchQuery, typeFilter, statusFilter], () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else {
      refetchData()
    }
  })

  const fetchSuppliers = (ctx, callback) => {
    const ordering = isSortDirDesc.value ? sortBy.value : `-${sortBy.value}`
    store
      .dispatch('app-supplier/fetchSuppliers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        ordering,
        type: typeFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const suppliers = response.data.results
        callback(suppliers)
        totalSuppliers.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching suppliers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchSuppliers,
    tableColumns,
    perPage,
    currentPage,
    totalSuppliers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSupplierListTable,
    refetchData,

    typeFilter,
    statusFilter,
  }
}
