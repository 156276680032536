<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    width="50rem"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    z-index="12"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Supplier
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit, invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="supplierData.name"
                :state="getValidationState(validationContext)"
                trim
                :maxlength="50"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider
            #default="validationContext"
            name="Type"
            rules="required"
          >
            <b-form-group
              label="Type *"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="supplierData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Street Address -->
          <validation-provider
            #default="validationContext"
            name="Street Address"
            rules="required"
          >
            <b-form-group
              label="Street Address *"
              label-for="street-address"
            >
              <vue-google-autocomplete
                id="street-address"
                ref="address"
                v-model="supplierData.street_address"
                country="pt"
                placeholder=""
                class="form-control"
                @placechanged="getAddressData"
                @change="onChange"
              />
              <b-form-input
                id="street-address"
                v-model="supplierData.street_address_2"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Zip code -->
          <b-form-group
            label="ZIP/Postal Code"
            label-for="zip-code"
          >
            <b-form-input
              id="zip-code"
              v-model="supplierData.zip_code"
              :maxlength="10"
              trim
            />
          </b-form-group>

          <!-- Location -->
          <b-form-group
            label="Location"
            label-for="location"
          >
            <b-form-input
              id="location"
              v-model="supplierData.location"
              :maxlength="50"
              trim
            />
          </b-form-group>

          <!-- Country -->
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="supplierData.country"
              :maxlength="50"
              trim
            />
          </b-form-group>

          <!-- Website -->
          <b-form-group
            label="Website"
            label-for="website"
          >
            <b-form-input
              id="website"
              v-model="supplierData.website"
              :maxlength="50"
              trim
              type="url"
            />
          </b-form-group>

          <!-- Image -->
          <validation-provider
            #default="validationContext"
            name="Image"
            :rules="`size:${10 * 1024 * 1024}`"
          >
            <b-form-group
              label="Image"
              label-for="image"
            >
              <b-form-file
                v-model="supplierData.image"
                placeholder="Choose file or drop it here..."
                size="sm"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BForm, BFormFile, BFormGroup, BFormInput,
  BFormInvalidFeedback, BSidebar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  components: {
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BSidebar,
    vSelect,
    VueGoogleAutocomplete,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit, refs }) {
    const blankSupplierData = {
      name: '',
      type: '',
      street_address: '',
      street_address_2: '',
      zip_code: '',
      location: '',
      country: '',
      website: '',
    }
    const supplierData = ref(JSON.parse(JSON.stringify(blankSupplierData)))
    const userRole = JSON.parse(localStorage.getItem('userData')).role

    const validatorSize = (file, [fileSize]) => {
      const validSize = file.size < +fileSize
      const sizeMB = +fileSize / (1024 * 1024)
      return validSize || `File ${file.name} too big (> ${sizeMB}MB)`
    }

    const size = extend('size', {
      validate: validatorSize,
    })

    const resetData = () => {
      supplierData.value = JSON.parse(JSON.stringify(blankSupplierData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    const selectedPlace = ref({})
    const getAddressData = addressData => {
      selectedPlace.value = { ...addressData }
      if (addressData.locality) {
        supplierData.value.location = addressData.locality
      }
      if (addressData.country) {
        supplierData.value.country = addressData.country
      }
      if (addressData.postal_code) {
        supplierData.value.zip_code = addressData.postal_code
      }
    }

    const onChange = text => {
      if (!text) {
        supplierData.value.street_address = ''
        return
      }
      let streetAddress = ''
      if (selectedPlace.value && selectedPlace.value.route) {
        streetAddress = selectedPlace.value.route
        if (selectedPlace.value.street_number) {
          streetAddress += `, ${selectedPlace.value.street_number}`
        }
      } else {
        streetAddress = text
      }
      supplierData.value.street_address = streetAddress
      refs.address.update(streetAddress)
      selectedPlace.value = {}
    }

    const onSubmit = () => {
      const formData = new FormData()
      Object.keys(supplierData.value).forEach(
        key => formData.append(key, supplierData.value[key]),
      )
      store.dispatch('app-supplier/addSupplier', formData)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
          resetForm()
        })
    }

    return {
      supplierData,
      userRole,
      size,
      getAddressData,
      onChange,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
