import axios from '@axios'

export default {
  namespaced: true,
  state: {
    formOptions: {},
  },
  getters: {
    typeOptions: state => {
      const { types } = state.formOptions
      return types ? types.options : []
    },
    statusOptions: state => {
      const { status } = state.formOptions
      return status ? status.options : []
    },
  },
  mutations: {
    UPDATE_FORM_OPTIONS(state, formOptions) {
      state.formOptions = formOptions
    },
  },
  actions: {
    fetchSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/suppliers-list/', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFormOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/suppliers-list/suppliers_options/')
          .then(response => {
            commit('UPDATE_FORM_OPTIONS', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addSupplier(ctx, supplierData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/suppliers-add/', supplierData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSupplier(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/suppliers-edit/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editSupplier(ctx, { id, ...supplierData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/suppliers-edit/${id}/`, supplierData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadSupplierImage(ctx, { id, formData }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/api/v1/suppliers-upload-image/${id}/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
